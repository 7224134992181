import React,{Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import css from '../Footer/footer.css';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: 'calc(100% - 220px)',
    textAlign: 'center',
    left: '220px',
    bottom: '0',
    '@media (max-width:767px)': {
      width: '100%',
      left: '0'
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    background:'#f9ecec1f',
    textAlign:'center'
  },
}));

class Footer extends Component{
  constructor(props) {
    super(props)
    this.state = {
      sideShow: true,
      isLoading: true,
    }
  }
   
render(){
  const { classes } = this.props
  
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
      <div className="footer-padd">
      Copyright © { new Date().getFullYear() }  All rights Reserved at Titanium Payments 
      </div>
      </Paper> 
      
    </div>
  );
}
}

const ApplyingStylesOnClasses = (props) => {
  const classes = useStyles()
  return (<Footer classes={classes} {...props} />)
}
export default withRouter(ApplyingStylesOnClasses)

