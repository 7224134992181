import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import classes from './Company.module.scss';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Textfield } from './../../components/Textfield';
import { Buttons } from './../../components/Buttons';
import { ErrorMessage } from './../../components/Message/ErrorMessage';
import { SuccessMessage } from './../../components/Message/SuccessMessage';
import { locationDetailById, locationEdit } from '../../actions/LocationAction'
import queryString from 'query-string';
import { countryDataList } from '../../actions/CompanyAction'
import TypableSelect from './../../helpers/TypableSelect';
import FormControl from '@material-ui/core/FormControl';
import validator from './../../helpers/checkValidations';
import LoadingOverlay from 'react-loading-overlay';
import config from './../../constants/config'

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();
const mapToFormatCountry = (values) => {
  return values.sort().map(Obj => (
    { value: Obj.Country, label: Obj.Country }
  ))
};
const mapToFormatStates = (state) => {
  return Object.keys(state).sort().map(name => (
    { value: name, label: name }
  ))
};

const mapToFormatCity = (state) => {
  return state.sort().map(name => (
    { value: name, title: name }
  ))
};

const getValidationStyle = cond => {
  if (cond) {
    return { width: '100%', border: '2px solid red' };
  } else {
    return { width: '100%' };
  }
}

class EditLocationsCompanyDetails extends Component {
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this);
    this.state = {
      sideShow: true,
      isSubmit: false,
      isLoading: true,
      locationName: '',
      email: '',
      mId: '',
      mId2: '',
      phone: '',
      contactName: '',
      address1: '',
      address2: "",
      country: '',
      state: '',
      city: '',
      stateList: {},
      cityList: [],
      Countrydata: [],
      zip: '',
      company: '',
      notes: '',
      timeZone: new Date(),
      misMatchError: [],
      successMessages: false,
      paytraceKey: '',
      paytraceKeyNew: [],
      paytracePassword: "",
      paytraceUserName: "",
      paytraceKey2: '',
      paytracePassword2: "",
      paytraceUserName2: "",
      error: {
        nameError: false,
        paytracePasswordError: false,
        paytraceUserNameError: false,
        paytracePassword2Error: false,
        paytraceUserName2Error: false,
        locationNameError: false,
        phoneError: false,
        mIdError: "",
        mId2Error: "",
        emailError: false,
        contactNameError: false,
        mccError: false,
        address1Error: false,
        stateError: false,
        cityError: false,
        zipError: false,
        timeZoneError: false,
      }
    }
  }
  async getcountryDataList() {
    this.setState({ isLoading: true })
    await this.props.countryDataList().then((res) => {
      this.setState({ Countrydata: res.payload.companyData.data, isLoading: false })
    })
  }
  getLocationData() {
    let search = this.props.location.search;
    let param = queryString.parse(search);
    this.setState({ isLoading: true });
    this.props.locationDetailById(param.locations).then((res) => { 
      this.setState({ ...res.payload.locationData.data.response, isLoading: false });
      setTimeout(() => {  
        let country2update = res.payload.locationData.data.response.country;
        if (country2update && country2update !== null) {
          this.setState({
            stateList: this.state.Countrydata.filter(obj => (obj.Country === country2update))[0].State, country: country2update
          });
        }
        let state2update = res.payload.locationData.data.response.state;
        if (state2update && state2update !== null)
          this.setState({
            cityList: this.state.stateList[state2update], state: state2update
          });
      }, 500);
    });
  }
  async componentDidMount() {
    await this.getcountryDataList();
    this.getLocationData();
  }
  handleClickMenu(e) {
    if (this.state.sideShow)
      this.setState({ sideShow: false });
    else
      this.setState({ sideShow: true });
  }
  handleChange = (event, t) => {
    let error = {}
    let misMatchError = [];
    let { id, value } = event.target;
    if (id === 'phone' && value.toString().length === 11) {
      return false;
    }
    (validator(id, value)) ? (error[id + 'Error'] = true) : (error[id + 'Error'] = false)
    this.setState({ [id]: value, error, misMatchError, isSubmit: false, successMessages: false });
  }
  handleSubmit(e, t) {
    let misMatchError = [];
    let successMessages = false;
    let state = this.state;
    let error = {};
    let errorFlag = false;
    this.setState({ isSubmit: true }); 
    Object.keys(state).map((key, value) => {
      if (key === "address2" && state['address2'] === "") {
        return false;
      }
      if (key === "notes" && state['notes'] === "") {
        return false;
      }
      if (key === "phone" && state['phone'] === "") {
        return false;
      }
      if ((this.state.mId2.trim() == '' && key == 'mId2') || (this.state.mId2.trim() == '' && key == 'paytraceUserName2') || (this.state.mId2.trim() == '' && key == 'paytracePassword2') || (this.state.mId2.trim() == '' && key == 'paytraceKey2') || (key == 'paytraceKey')) {
        return '';
      } else {
        if ((validator(key, state[key]))) {
          return (validator(key, state[key])) ? (error[key + 'Error'] = true, errorFlag = true) : (error[key + 'Error'] = false)
        } else {
          return (state[key] === '' || (typeof state[key] === 'string' && state[key].trim() === '')) ? (error[key + 'Error'] = true, errorFlag = true) : ''
        }
      }
    });
    if (!(((this.state.paytraceKeyNew.length == 1) || this.state.paytraceKey) || (((this.state.paytraceKeyNew.length == 2) || this.state.paytraceKey2) && (this.state.mId2.trim() != '')))) {
      misMatchError.push('Paytrace key required for payment.');
      this.setState({ misMatchError });
      errorFlag = true;
    }
    if (errorFlag) {
      console.error("errorFlag ", errorFlag, misMatchError, error, successMessages);
      this.setState({ misMatchError, error, successMessages });
      return false;
    } else {
      delete this.state._id;
      if (window.confirm('Are you sure want to update?')) {
        this.setState({ isLoading: true })
        const data = new FormData();
        const { paytraceKeyNew } = this.state;
        for (const key in this.state) {
          if ("paytraceKeyNew" === key) {
            continue;
          }
          data.append(key, this.state[key]);
        }
        if (paytraceKeyNew) {
          for (const keys in paytraceKeyNew) {
            data.append("paytraceKeyNew", paytraceKeyNew[keys]);
          }
        }
 
        this.props.locationEdit(data).then(result => {
          window.scrollTo(0, 0);
          if (result !== undefined && result.type === 'LOCATION_SUCCESS') {
            this.setState({ successMessages: true, misMatchError, isLoading: false }, () => {
              setTimeout(() => {
                this.goBack(e);
              }, 2000)
            })
          }
          if (result !== undefined && result.type === 'LOCATION_ERROR') {
            this.setState({ misMatchError: this.props.info.locationInfo.data.message, isLoading: false, successMessages });
          }
        });
      }
    }
  }
  onCloseTypable = (i) => {
    console.info('>>>', i);
  }
  setCountryState(val) {
    if (val !== null)
      this.setState({
        stateList: this.state.Countrydata.filter(obj => (obj.Country === val.label))[0].State, country: val.label
      });
  }
  setStatesSet(val) {
    if (val !== null)
      this.setState({
        cityList: this.state.stateList[val.label], state: val.label, city: ""
      })
  }
  /* setCitySet(val) {
    if (val !== null)
      this.setState({ city: val.label })
  } */
  setCitySet(event, val) {
    if (val !== null && val.hasOwnProperty('value')) {
      this.setState({ city: val.title })
    } else if (val && val.inputValue) {
      this.setState({ city: val.inputValue })
    } else {
      this.setState({ city: val })
    }
  }
  goBack(e) {
    this.props.handleChangeTab(e, 1);
  }
  //upload paytace key

  uploadKey = (event, t) => {
    let file = event.target.files[0];
    this.state.paytraceKeyNew[0] = file;
    this.setState({ paytraceKeyNew: this.state.paytraceKeyNew });
  }
  uploadKey2 = (event, t) => {
    let file2 = event.target.files[0];
    this.state.paytraceKeyNew[1] = file2;
    this.setState({ paytraceKeyNew: this.state.paytraceKeyNew });
  }
  render() {
    const { t } = this.props;
    const { Countrydata } = this.state

    return (
      <LoadingOverlay
        className={classes.tpTableFullW}
        active={this.state.isLoading}
        spinner
        text='Fetching your response ...'>
        <Container component="main" maxWidth="md" >
          {
            this.state.misMatchError.length > 0 ? < ErrorMessage errors={[this.state.misMatchError]} /> : ''
          }
          {
            this.state.successMessages ? <SuccessMessage successes={[this.props.info.locationInfo.data.message]} /> : ''
          }
          <form className={classes.form} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.locationNameError}
                  id="locationName"
                  type="text"
                  labels={t("Location Name")}
                  value={this.state.locationName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  focus={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Textfield
                  required={true}
                  error={this.state.error.contactNameError}
                  id="contactName"
                  type="text"
                  labels={t("Contact Name")}
                  value={this.state.contactName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.emailError}
                  id="email"
                  type="email"
                  labels={t("Email")}
                  value={this.state.email}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  helperText="phone format xxx-xxx-xxxx"
                  id="phone"
                  type="text"
                  labels={t("Phone")}
                  value={this.state.phone}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  helperText="Traditional MID"
                  required={true}
                  error={this.state.error.mIdError}
                  id="mId"
                  type={"text"}
                  labels={t("Traditional")}
                  value={this.state.mId}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  helperText="Surcharge MID"
                  error={this.state.error.mId2Error}
                  id="mId2"
                  type={"text"}
                  labels={t("Surcharge")}
                  value={this.state.mId2}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.address1Error}
                  id="address1"
                  type="text"
                  labels={t("Address1")}
                  value={this.state.address1}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  id="address2"
                  type="text"
                  labels={t("Address2")}
                  value={this.state.address2}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.country && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select Country Name'} suggestions={mapToFormatCountry(Countrydata)}
                    onChange={val => this.setCountryState(val)}
                    isSelected={(this.state.country) ? this.state.country : ''}
                    onCloseTypable={this.onCloseTypable}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.state && this.state.isSubmit)} >
                  <TypableSelect
                    placeholder={'Select State Name'} suggestions={mapToFormatStates(this.state.stateList)}
                    onChange={val => this.setStatesSet(val)}
                    isSelected={this.state.state}
                    isClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '4.1rem' }}>
                <FormControl fullWidth style={getValidationStyle(!this.state.city && this.state.isSubmit)} >
                  <Autocomplete
                    value={this.state.city}
                    onChange={(e, value) => this.setCitySet(e, value)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          title: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="city"
                    options={mapToFormatCity(this.state.cityList)}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    renderOption={(option) => option.title}
                    //  style={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="city" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '2rem' }}>
                <Textfield
                  required={true}
                  error={this.state.error.zipError}
                  id="zip"
                  type="text"
                  labels={t("ZIP")}
                  value={this.state.zip}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={12}> <h3>Paytrace Api Credentials 1</h3></Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.paytraceUserNameError}
                  id="paytraceUserName"
                  type="text"
                  labels={t("User Name")}
                  value={this.state.paytraceUserName}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.paytracePasswordError}
                  id="paytracePassword"
                  type="password"
                  labels={t("Password")}
                  value={this.state.paytracePassword}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>

              <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                <label>Paytrace key file
                  {((this.state.paytraceKey && this.state.paytraceKey.trim() != "") || (this.state.paytraceKeyNew[1] && this.state.paytraceKeyNew[1] != "")) ? <img alt="enable" className={classes.rightImg} src={config.PAYTRACE_AWS_PATH + 'checkmark-24.png'} /> : ''}
                </label>
                <input type="file" name="paytraceKey" onChange={(e) => this.uploadKey(e, t)} />
              </Grid>
              <Grid item xs={6} sm={3} style={{ marginTop: '1rem', wordBreak: 'break-word', fontWeight: '500' }}>
                {this.state.paytraceKey ? this.state.paytraceKey : ''}
              </Grid>
              {(this.state.mId2.trim() != '') ?
                <>
                  <Grid item xs={12}> <h3>Paytrace Api Credentials 2</h3></Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={this.state.mId2.trim() != '' ? true : false}
                      error={this.state.error.paytraceUserName2Error}
                      id="paytraceUserName2"
                      type="text"
                      labels={t("User Name")}
                      value={this.state.paytraceUserName2}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Textfield
                      required={this.state.mId2.trim() != '' ? true : false}
                      error={this.state.error.paytracePassword2Error}
                      id="paytracePassword2"
                      type="password"
                      labels={t("Password")}
                      value={this.state.paytracePassword2}
                      width={true}
                      onChange={(e) => this.handleChange(e, t)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '1.5rem' }}>
                    <label>Paytrace key file
              {((this.state.paytraceKey2 && this.state.paytraceKey2.trim() != "") || (this.state.paytraceKeyNew[1] && this.state.paytraceKeyNew[1] != "")) ? <img alt="enable" className={classes.rightImg} src={config.APPURL + '/assets/images/checkmark-24.png'} /> : ''}
                    </label>
                    <input type="file" name="paytraceKey2" onChange={(e) => this.uploadKey2(e, t)} />
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ marginTop: '1rem', wordBreak: 'break-word', fontWeight: '500' }}>
                    {this.state.paytraceKey2 ? this.state.paytraceKey2 : ''}
                  </Grid>
                </> : ''}

              <Grid item xs={6} sm={3}>
                <Textfield
                  required={true}
                  error={this.state.error.merchantIdError}
                  id="company"
                  type="text"
                  labels={t("Titanium Merchant Id")}
                  value={this.state.company}
                  width={true}
                  onChange={(e) => this.handleChange(e, t)}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6} sm={6} style={{ marginTop: '1.5rem' }}>
                <Textfield
                  id="notes"
                  type="text"
                  labels={t("Notes")}
                  value={this.state.notes}
                  width={true}
                  rowsMax={"2"}
                  multiline={true}
                  onChange={(e) => this.handleChange(e, t)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Buttons
                  id="signUp"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => this.handleSubmit(e, t)}
                  text={t("Update")}
                />
                <Buttons
                  className={classes.ml_2}
                  variant="contained"
                  color="secondary"
                  text={t("Back")}
                  onClick={(e) => this.goBack(e)}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </LoadingOverlay>
    )
  }
}

function mapStateToProps(state) {
  return {
    info: state.LocationReducer
  };
}
export default connect(mapStateToProps, { locationDetailById, countryDataList, locationEdit })(withTranslation()(withRouter(EditLocationsCompanyDetails)));
